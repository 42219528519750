import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import FallbackThumbnail from 'modules/users-chats/chat-details/print-messages/image-message/FallbackThumbnail';
import MessageNotification from 'shared/message-notification/MessageNotification';

import { If } from 'shared/utilities';

export default function ImageMessageContainer( props ) {
	const { isValidSrc, clientKey, isMessageBlock, altTitle, children } = props;
	return (
		<ImageContainerWrapper container>
			<StyledCard elevation={1}>
				<ContentContainer item>
					<If
						condition={isValidSrc}
						otherwise={
							<FallbackThumbnail text={altTitle} sx={Sxs.FallbackThumbnail} />
						}>
						{children}
					</If>
				</ContentContainer>
				<StyledCardContent>
					<MessageNotification date={clientKey} messageReadStatus={isMessageBlock ? 'delivered' : 'read'} />
				</StyledCardContent>
			</StyledCard>
		</ImageContainerWrapper>
	);
}

const ImageContainerWrapper = styled(Grid)(( { theme, isMine } ) => ({
	height: '100%',
	flexDirection: isMine ? 'row' : 'row-reverse',
	justifyContent: !isMine ? 'flex-start' : 'flex-end',
}));

const StyledCard = styled(Card)({
	borderRadius: '8px',
	margin: '4px 0px',

});

const StyledCardContent = styled(CardContent)(( { theme, isMessageBlock } ) => ({
	backgroundColor: isMessageBlock ? theme.palette.customPalette.error : undefined,
	color: isMessageBlock ? 'white' : undefined,
	'&.MuiCardContent-root': {
		padding: theme.spacing(1.5),
	},
}));

const ContentContainer = styled(Grid)({
	backgroundImage: 'linear-gradient(to right top, #44444a, #344551,#1b484c,#1d4836,#374419)',
});

const Sxs = {
	FallbackThumbnail: {
		width: 280,
		height: 280,
	},
};
