import React, { useState, useEffect } from 'react';
import styles from './Login.module.scss';

import Form from 'shared/form';
import { TextInput } from 'shared/inputs';
import Button from 'shared/button';
import { Skeleton } from 'shared/skeleton';
import PasswordInput from '../password-input';

import Logo from 'assets/Logo';

import * as yup from 'yup';
import { useFormContext } from 'react-hook-form';
import { useAuthContext } from '../context';
import { useHistory } from 'react-router-dom';

import routes from 'routing/routes';

import { useColor } from 'hooks';

const initialValues = {
  email: '',
  password: '',
};

const loginValidation = yup.object({
  email: yup.string().email().lowercase().required(),
  password: yup.string().required(),
});

const EmailInput = ({ name, error, setError }) => {
  const { watch } = useFormContext();

  const value = watch(name);

  useEffect(() => {
    if (!value) {
      setError();
    }
  }, []);

  return (
    <TextInput
      className={styles.input}
      name={name}
      label="Email"
      error={!!error}
      helperText={error}
      inputProps={{ autoComplete: 'username' }}
      nativeOutline
    />
  );
};

const SubmitButton = () => {
  const { formState } = useFormContext();

  return (
    <Button
      className={styles.button}
      display="primary"
      type="submit"
      disabled={!formState.isValid}
    >
      Log in
    </Button>
  );
};

const Login = () => {
  const [error, setError] = useState();
  const [loading, toggleLoading] = useState(false);
  const { login } = useAuthContext();
  const history = useHistory();
  const logoColor = useColor();

  const handleSubmit = (form) => {
    toggleLoading(true);
    setError(null);
    login({ form, setError });
  };

  useEffect(() => {
    if (error) {
      toggleLoading(false);
    }
  }, [error]);

  return (
    <div className={styles.page}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Welcome to</h2>
        <Logo className={styles.logo} color={logoColor} />
        <Form
          className={styles.form}
          initialValues={initialValues}
          validation={loginValidation}
          onSubmit={handleSubmit}
        >
          {loading ? (
            <Skeleton amount={2} />
          ) : (
            <>
              <EmailInput name="email" error={error} setError={setError} />

              <PasswordInput
                className={styles.input}
                name="password"
                label="Password"
                inputProps={{ autoComplete: 'current-password' }}
                nativeOutline
                error={error}
              />

              <p
                className={styles.forgot}
                onClick={() => {
                  history.push(routes.passwordForgot);
                }}
              >
                Forgot password?
              </p>
              <SubmitButton />
            </>
          )}
        </Form>
      </div>
    </div>
  );
};
export default Login;
