import React from 'react';
import styles from './PasswordInput.module.scss';

import InputAdornment from '@mui/material/InputAdornment';
import { TextInput } from 'shared/inputs';

import Shown from '@mui/icons-material/Visibility';
import Hidden from '@mui/icons-material/VisibilityOff';

import { useToggler } from 'hooks';

const PasswordInput = ({ error, ...props }) => {
  const [showText, toggleShowText] = useToggler();

  const Icon = showText ? Shown : Hidden;

  return (
    <TextInput
      variant="outlined"
      error={!!error}
      InputProps={{
        endAdornment: (
          <InputAdornment
            className={styles.input__icon}
            position="end"
            onClick={toggleShowText}
          >
            <Icon color="disabled" />
          </InputAdornment>
        ),
      }}
      type={showText ? 'text' : 'password'}
      {...props}
      hidden
    />
  );
};

export default PasswordInput;
