import { useEffect, useRef, useState } from 'react';
import { from, Subject } from 'rxjs';
import { useToggler } from './index';
import { map, switchMap, tap } from 'rxjs/operators';
import { useParams } from 'react-router';

const useQueryCall = ({
  sdkCmd,
  payload,
  mapSchema = (res) => res,
  dependencies = [],
  enabled,
}) => {
  const [loadData$] = useState(new Subject());
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [loading, toggleLoading] = useToggler();

  function loadInfo(payload) {
    return from(sdkCmd(payload)).pipe(map(mapSchema));
  }

  useEffect(() => {
    if (enabled) {
      const sub = loadData$
        .pipe(
          tap(() => toggleLoading(true)),
          switchMap((payload) => {
            return loadInfo(payload);
          })
        )
        .subscribe({
          next: (results) => {
            setData(results);
            toggleLoading(false);
          },
          error: (err) => {
            console.log(err);
            setError(err);
            toggleLoading(false);
          },
        });
      loadData$.next(payload);
      return () => sub.unsubscribe();
    }
  }, [loadData$, enabled, ...dependencies]);

  return { loading, data, error, setData };
};

export default useQueryCall;
