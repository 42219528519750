import React, { useCallback } from 'react';
import styles from './NewPassword.module.scss';

import Form from 'shared/form';

import Button from 'shared/button';
import { Skeleton } from 'shared/skeleton';
import PasswordInput from '../password-input';

import Logo from 'assets/Logo';

import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useAuthContext } from '../context';

import { useColor } from 'hooks';

const initialValues = {
  password: '',
  newPassword: '',
};

const passwordValidation = yup.object().shape({
  password: yup.string().required('Required'),
  newPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], "Passwords don't match!")
    .required('Required'),
});

const SubmitButton = () => {
  const { formState } = useFormContext();

  return (
    <Button
      className={styles.button}
      display="primary"
      type="submit"
      disabled={formState.isDirty && !formState.isValid}
    >
      Log in
    </Button>
  );
};

const NewPassword = () => {
  const { loading, setNewPassword } = useAuthContext();
  const location = useLocation();
  const { email: user } = location.state;
  const logoColor = useColor();

  const handleSubmit = useCallback(
    (form) => {
      setNewPassword({ form, user });
    },
    [setNewPassword]
  );

  return (
    <div className={styles.page}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Welcome to</h2>
        <Logo className={styles.logo} color={logoColor} />
        <Form
          className={styles.form}
          initialValues={initialValues}
          validation={passwordValidation}
          onSubmit={handleSubmit}
        >
          {loading ? (
            <Skeleton amount={2} />
          ) : (
            <>
              <PasswordInput
                className={styles.input}
                name="password"
                label="Password"
                inputProps={{ autoComplete: 'current-password' }}
                nativeOutline
              />
              <PasswordInput
                className={styles.input}
                name="newPassword"
                label="New Password"
                inputProps={{ autoComplete: 'current-password' }}
                nativeOutline
              />
              <p></p>
              <SubmitButton onClick />
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default NewPassword;
