import { Card } from '@mui/material';

import CardInfo from './CardInfo';
import CardVoice from './CardVoice';
import CardActions from './CardActions';

import { If } from 'shared/utilities';

export default function VoiceMessageCard( props ) {
	const { id = '', clientKey, owner = '', recipient = '', searchTerm = '', ownerId, date, channelId, statusData, medialLocation, text } = props;
	const isBlocked = statusData?.status === 'blocked';
	const { sxCardWrapper } = useTextMessageCardStyles(isBlocked);

	return (
		<Card sx={sxCardWrapper} elevation={3}>
			<CardInfo id={id} owner={owner} recipient={recipient} searchTerm={searchTerm} date={date} ownerId={ownerId} />
			<If condition={medialLocation}>
				<CardVoice mediaLocation={medialLocation} text={text} searchTerm={searchTerm} />
			</If>
			<CardActions id={id} clientKey={clientKey} ownerId={ownerId} channelId={channelId} isBlocked={isBlocked} reason={statusData.reason} />
		</Card>
	);
}

function useTextMessageCardStyles( isBlocked ) {
	return {
		sxCardWrapper: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			height: '100%',
			borderRadius: 4,
			border: isBlocked && '1 px solid',
			borderColor: isBlocked && 'customPalette.error',
		},
	};
}
