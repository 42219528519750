import React from 'react';
import styles from './SignUp.module.scss';

import NewPasswordForm from './NewPasswordForm';

import Logo from 'assets/Logo';
import { Skeleton } from 'shared/skeleton';
import { If } from 'shared/utilities';

import usePasswordReset from './usePasswordReset';
import { useAuthContext } from '../context';

const PasswordReset = () => {
  const { challenge } = useAuthContext();
  const { userSection, loading = false } = usePasswordReset();
  const hasUser = !!challenge || !!userSection;

  return (
    <div className={styles.page}>
      <div className={styles.wrapper}>
        <Logo className={styles.logo} />
        <p className={styles.title}>Update your password</p>
        <If condition={hasUser && !loading} otherwise={<Skeleton amount={2} />}>
          <NewPasswordForm user={challenge || userSection} />
        </If>
      </div>
    </div>
  );
};

export default PasswordReset;
