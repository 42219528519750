export const MEDIA_TYPES_COLORS = ['#468B97', '#EF6262', '#F3AA60', '#00C1D4', '#C490E4', '#75C2F6', '#BA704F'];

export const MODERATION_TOTAL_COLORS = ['#468B97', '#EF6262', '#75C2F6', '#F3AA60', '#00C1D4', '#C490E4', '#BA704F'];

export const CHANEL_MODERATION_COLORS = ['#468B97', '#EF6262'];

export const SENTIMENTS_TREND_COLORS = [
	{
		id: 'neutral',
		color: '#8CC0DE',
	},
	{
		id: 'negative',
		color: '#DF2E38',
	},
	{
		id: 'positive',
		color: '#5D9C59',
	},
];
