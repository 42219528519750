import ProtectedApp from './ProtectedApp';
import UnprotectedApp from './UnprotectedApp';

import { AppLoader } from './shared/loaders';
import ErrorBoundary from 'shared/error-boundary';

import { useAuthContext } from 'modules/auth/context';

const App = () => {
	const { isAuth } = useAuthContext();
	if (isAuth === undefined) return <AppLoader />;

	return isAuth ? (
		<ErrorBoundary level='global'>
			<ProtectedApp />
		</ErrorBoundary>
	) : (
		<ErrorBoundary level='global'>
			<UnprotectedApp />
		</ErrorBoundary>
	);
};

export default App;