import { Grid, Typography } from '@mui/material';

import { Logout } from '../../Icons';
import { useAuthContext } from 'modules/auth/context';

export default function LogoutOption() {
  const { signOut } = useAuthContext();
  return (
    <Grid container direction='row' gap={2} onClick={signOut}>
      <Logout />
      <Typography component='span'>Logout</Typography>
    </Grid>
  );
}