import React, { useState } from 'react';
import styles from '../login/Login.module.scss';

import Form from 'shared/form';
import { TextInput } from 'shared/inputs';
import Button from 'shared/button';

import Logo from 'assets/Logo';

import * as yup from 'yup';
import { useFormContext } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import { useGraphql } from 'context';

const initialValues = {
	email: '',
};

const resetEmailValidation = yup.object({
	email: yup.string().email().lowercase().required(),
});

const EmailInput = ( { name } ) => {
	return (
		<TextInput
			className={styles.input}
			name={name}
			label='Email'
			inputProps={{ autoComplete: 'username' }}
			nativeOutline
		/>
	);
};

const SendTempPswdButton = () => {
	const { formState } = useFormContext();

	return (
		<Button
			className={styles.button}
			display='primary'
			type='submit'
			disabled={!formState.isValid}
		>
			Reset Password
		</Button>
	);
};

const PasswordForgot = () => {
	const history = useHistory();
	const api = useGraphql();
	const [requestIsSend, setRequestIsSend] = useState(false);
	const requestPswReset = ( email ) => {
		api.controller.doc
		.requestPasswordReset(email)
		.then(setRequestIsSend(true))
		.catch(( err ) => console.log('Call error on submit', err));
	};

	return (
		<div className={styles.page}>
			<div className={styles.wrapper}>
				<h2 className={styles.title}>Forgot Password</h2>
				<Logo className={styles.logo} />
				<Form
					className={styles.form}
					initialValues={initialValues}
					validation={resetEmailValidation}
					onSubmit={( form ) => requestPswReset(form)}
				>
					<>
						<EmailInput name='email' />
						{requestIsSend && (
							<p className={styles.sendedToEmailMsg}>
								Your password reset request has been sent to the email you
								provided
							</p>
						)}
						<p className={styles.forgot} onClick={history.goBack}>
							Go back to Login
						</p>
						{requestIsSend || <SendTempPswdButton />}
					</>
				</Form>
			</div>
		</div>
	);
};

export default PasswordForgot;