import React, { useRef, useCallback, useMemo, useState } from 'react';
import styles from './BulletinsList.module.scss';

import Grid from '@mui/material/Grid';

import InfiniteScroller from 'shared/infinite-scroller';
import BulletinsSearch from 'modules/bulletins/bulletins-search';
import CreateBulletin from 'modules/bulletins/create-bulletin';
import BulletinsItem from './BulletinItem';
import BulletinsFilter from 'modules/bulletins/bulletin-filter';
import TableLoader from 'shared/loaders/TableLoader';
import Suspense from 'shared/suspense-list';

import { useBulletinsContext } from '../context';
import { sortReducer, useToogleSort } from 'hooks/useSort';

const initialPayload = {
	type: 'date',
	descendent: false,
	key: 'createdAt',
};

const BulletinsList = () => {
	const {
		bulletins,
		loading,
		loadBulletins,
		loadNextPage,
		hasPermission,
		searchInput,
	} = useBulletinsContext();
	const list = useRef();
	const [payload, setPayload] = useState(initialPayload);
	const [order, toggleOrder] = useToogleSort(false);

	const handleCreate = useCallback(() => {
		loadBulletins();
	}, [loadBulletins]);

	const searchValue = useMemo(() => searchInput, [loading]);

	const sortedBulletins = useMemo(() => {
		toggleOrder();
		return sortReducer(bulletins, payload);
	}, [payload, bulletins]);

	function sortByName() {
		setPayload({
			type: 'name',
			descendent: order,
			key: 'sentBy',
		});
	}

	function sortBySubject() {
		setPayload({
			type: 'string',
			descendent: order,
			key: 'subject',
		});
	}

	function sortBySent() {
		setPayload({
			type: 'number',
			descendent: order,
			key: 'sent',
		});
		toggleOrder();
	}

	function sortByDate() {
		setPayload({
			type: 'date',
			descendent: order,
			key: 'createdAt',
		});
		toggleOrder();
	}

	return (
		<Grid
			classes={{ root: styles.wrapper }}
			direction='column'
			alignItems='center'
			wrap='nowrap'
			item
			container
		>
			<Grid classes={{ root: styles.header }} justifyContent='stretch' alignItems='center' wrap='nowrap' item container>
				<BulletinsSearch />
				<BulletinsFilter />
				{hasPermission.createBulletin && (
					<CreateBulletin onCreate={handleCreate} />
				)}
			</Grid>
			<Grid classes={{ root: styles.content }}>
				<Grid classes={{ root: styles.content__header }} container>
					<Grid xs={3} item onClick={sortByName} sx={{ cursor: 'pointer' }}>
						Sender
					</Grid>
					<Grid xs={3} item onClick={sortBySubject} sx={{ cursor: 'pointer' }}>
						Subject
					</Grid>
					<Grid xs={2} item>
						Recipient
					</Grid>
					<Grid xs={2} item onClick={sortBySent} sx={{ cursor: 'pointer' }}>
						Sent/Viewed
					</Grid>
					<Grid xs={2} item onClick={sortByDate} sx={{ cursor: 'pointer' }}>
						Date
					</Grid>
				</Grid>
				<Suspense
					total={bulletins ? bulletins.length : 0}
					loading={loading}
					fallback={<TableLoader />}
					textNoResult={!searchValue ? 'There are no announcements' : `There are no search results for announcements with "${searchValue}"`}>
					<Grid ref={list} classes={{ root: styles.content__body }}>
						{sortedBulletins.map(( item ) => (
							<BulletinsItem key={item.id} {...item} />
						))}
						<InfiniteScroller
							containerRef={list}
							loading={loading}
							onLoadNext={loadNextPage} />
					</Grid>
				</Suspense>
			</Grid>
		</Grid>
	);
};

export default BulletinsList;
