import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  AgencyFacilitiesQuery,
  AgencyInfoQuery,
  DOCUserFavesQuery,
  DOCUserInfoQuery,
  FacilityHUQuery,
} from 'kiwi-sdk';

import { catchError, from, of, Subject, throwError, zip } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { toast } from 'react-toastify';

import { useGraphql } from 'context';
import { useToggler } from 'hooks';

function handleSetFacilities(user, facilities, getHousingUnitInfo) {
  const userAgency = user.security?.agency;
  const userFacilities = user.security?.facility;
  if (userAgency) {
    getHousingUnitInfo(facilities);
    return facilities;
  }
  if (userFacilities)
    return filterViewableFacilities(
      facilities,
      userFacilities,
      getHousingUnitInfo
    );
}

function filterViewableFacilities(
  agencyFacilities,
  userViewableFacilities,
  getHousingUnitInfo
) {
  if (!userViewableFacilities) return null;
  const filteredArray = agencyFacilities.filter((agencyFacility) =>
    userViewableFacilities.find(
      (userFacility) => userFacility.id == agencyFacility.id
    )
  );
  getHousingUnitInfo(filteredArray);
  return filteredArray;
}

const getHousingUnitInfoBuilder =
  (setFacilitiesHousingUnitInfo, loadHousingUnits) => (userFacilities) => {
    const housingUnitArray = [];
    userFacilities.forEach((facility) => {
      loadHousingUnits({ id: facility.id }).subscribe((housingUnitList) => {
        housingUnitArray.push({
          housingUnits: housingUnitList.data.items,
          facilityId: facility.id,
          facilityName: facility.name,
        });
        if (housingUnitArray.length === userFacilities.length) {
          setFacilitiesHousingUnitInfo(housingUnitArray);
        }
      });
    });
  };

const AuthContext = createContext({});

const initAuthContext = () => {
  const api = useGraphql();
  const [loadBookmarks$] = useState(new Subject());
  const [isAuth, setIsAuth] = useState();
  const [user, setUser] = useState({});
  const [bookmarks, setBookmarks] = useState([]);
  const [agency, setAgency] = useState({});
  const [roles, setRoles] = useState([]);
  const [userPermissions, setUserPermissions] = useState(new Set());
  const [challenge, setChallenge] = useState();
  const [facilities, setFacilities] = useState([]);
  const [facilitiesHousingUnitInfo, setFacilitiesHousingUnitInfo] = useState(
    []
  );
  const [loading, toggleLoading] = useToggler();
  const [openBookmarksDrawer, toggleBookmarksDrawer] = useToggler();

  const getHousingUnitInfo = getHousingUnitInfoBuilder(
    setFacilitiesHousingUnitInfo,
    loadHousingUnits
  );

  function loadUser(id) {
    return from(api.send(DOCUserInfoQuery({ id }))).pipe(
      map((res) => res.data)
    );
  }

  function getBookmarks(id) {
    return from(api.send(DOCUserFavesQuery({ id }))).pipe(
      map((res) => res.data.faves ?? res.data)
    );
  }

  function getUserPermissions(userRoles, agencyRoles) {
    const userPermissions = userRoles.reduce((permissionAcum, userRole) => {
      const rolePermissions = agencyRoles.find(
        (agencyRole) => agencyRole.id == userRole.id
      ).permissions;
      return [...permissionAcum, ...rolePermissions];
    }, []);
    return new Set(userPermissions);
  }

  function loosePermissionCheck(requiredPermission) {
    const permissions = Array.from(userPermissions);
    return permissions.some((permission) =>
      permission.includes(requiredPermission)
    );
  }

  function removeLastNestedPermission(permission) {
    const lastNestedIndex = permission.lastIndexOf('#');
    return permission.slice(0, lastNestedIndex);
  }

  function strictPermissionCheck(requiredPermission) {
    if (userPermissions.has(requiredPermission)) {
      return true;
    }
    if (requiredPermission.includes('#')) {
      return strictPermissionCheck(
        removeLastNestedPermission(requiredPermission)
      );
    }
    return false;
  }

  function loadAgency(id) {
    return from(api.send(AgencyInfoQuery({ id }))).pipe(map((res) => res.data));
  }

  function loadRoles(id) {
    return from(api.controller.agency.roles({ id })).pipe(
      map((res) => res.items)
    );
  }

  function loadFacilities(agencyId) {
    return from(api.send(AgencyFacilitiesQuery({ id: agencyId }))).pipe(
      map((res) => res.data.items)
    );
  }

  function loadHousingUnits(payload) {
    return api.send(FacilityHUQuery(payload));
  }

  function handleSuccessLogin(data) {
    toggleLoading(true);
    const [user, agency, agencyFacilities, roles, bookmarks] = data;
    setAgency(agency);
    setFacilities(
      handleSetFacilities(user, agencyFacilities, getHousingUnitInfo)
    );
    setRoles(roles);
    setBookmarks(bookmarks);
    setUserPermissions(getUserPermissions(user.security.role, roles));
    toggleLoading(false);
    setIsAuth(true);
  }

  useEffect(() => {
    toggleLoading(true);
    setChallenge(null);
    from(api.auth.getCurrentUser())
      .pipe(
        switchMap((res) => loadUser(res.id)),
        tap((user) => {
          setUser(user);
        }),
        switchMap((user) => {
          return zip(
            of(user),
            loadAgency(user.agencyId),
            loadFacilities(user.agencyId),
            loadRoles(user.agencyId),
            getBookmarks(user.id)
          );
        })
      )
      .subscribe({
        next: handleSuccessLogin,
        error: () => {
          setIsAuth(false);
          toggleLoading(false);
        },
      });

    const bookmarksSub = loadBookmarks$
      .pipe(switchMap(getBookmarks))
      .subscribe(setBookmarks);

    return () => {
      bookmarksSub.unsubscribe();
    };
  }, []);

  const setNewPassword = useCallback(({ form, user }) => {
    toggleLoading(true);
    from(api.auth.completeNewPassword({ user, password: form.newPassword }))
      .pipe(
        switchMap((res) => loadUser(res.id)),
        tap((user) => {
          setUser(user);
        }),
        switchMap((user) => {
          return zip(
            of(user),
            loadAgency(user.agencyId),
            loadFacilities(user.agencyId),
            loadRoles(user.agencyId),
            getBookmarks(user.id)
          );
        })
      )
      .subscribe({
        next: handleSuccessLogin,
        error: (err) => {
          toggleLoading(false);
          toast.error(err.message);
        },
      });
    toggleLoading(false);
  }, []);

  function signIn(payload) {
    return from(api.auth.signIn(payload)).pipe(
      catchError((err) => {
        return throwError(err.message);
      })
    );
  }

  const login = useCallback(({ form, setError }) => {
    toggleLoading(true);
    signIn({ username: form.email, password: form.password })
      .pipe(
        switchMap((res) => {
          if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
            setChallenge(res);
            return res;
          }
          return loadUser(res.id);
        }),
        tap({
          next: (user) => setUser(user),
          error: (err) => {
            toggleLoading(false);
            const errorMessage = err.includes('password has expired')
              ? 'The password you entered is invalid'
              : err;
            setError(errorMessage);
            throwError(errorMessage);
            toggleLoading(false);
          },
        }),
        switchMap((user) => {
          return zip(
            of(user),
            loadAgency(user.agencyId),
            loadFacilities(user.agencyId),
            loadRoles(user.agencyId),
            getBookmarks(user.id)
          );
        })
      )
      .subscribe({
        next: handleSuccessLogin,
        error: (err) => {
          console.log('err', err);
          toggleLoading(false);
        },
      });
  }, []);

  const loadBookmarks = useCallback(() => {
    loadBookmarks$.next(user.id);
  }, [user.id]);

  const signOut = useCallback(() => {
    from(api.auth.signOut()).subscribe(() => {
      setChallenge(null);
      setIsAuth(false);
      setUser({});
      setAgency({});
      setRoles([]);
      setFacilities([]);
      setBookmarks([]);
    });
  }, []);

  return {
    openBookmarksDrawer,
    toggleBookmarksDrawer,
    isAuth,
    loading,
    toggleLoading,
    setNewPassword,
    login,
    signOut,
    user,
    challenge,
    bookmarks,
    agency,
    roles,
    facilities,
    loadBookmarks,
    facilitiesHousingUnitInfo,
    userPermissions,
    loosePermissionCheck,
    strictPermissionCheck,
  };
};

const AuthProvider = ({ children, ...props }) => {
  const {
    openBookmarksDrawer,
    toggleBookmarksDrawer,
    isAuth,
    loading,
    toggleLoading,
    setNewPassword,
    login,
    signOut,
    challenge,
    user,
    bookmarks,
    agency,
    roles,
    facilities,
    loadBookmarks,
    facilitiesHousingUnitInfo,
    userPermissions,
    loosePermissionCheck,
    strictPermissionCheck,
  } = initAuthContext(props);

  const contextValue = useMemo(
    () => ({
      openBookmarksDrawer,
      toggleBookmarksDrawer,
      isAuth,
      loading,
      toggleLoading,
      setNewPassword,
      login,
      signOut,
      challenge,
      user,
      bookmarks,
      agency,
      facilities,
      roles,
      loadBookmarks,
      facilitiesHousingUnitInfo,
      userPermissions,
      loosePermissionCheck,
      strictPermissionCheck,
    }),
    [
      isAuth,
      loading,
      setNewPassword,
      login,
      signOut,
      user,
      bookmarks,
      challenge,
      agency,
      roles,
      facilities,
      loadBookmarks,
      facilitiesHousingUnitInfo,
      userPermissions,
      loosePermissionCheck,
      strictPermissionCheck,
    ]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);

  return context;
};

export { AuthProvider, useAuthContext };
