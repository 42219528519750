import { Box, CardMedia, Paper, Typography } from '@mui/material';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

import format from 'date-fns/format';
import MemberInfo from './MemberInfo';
import MediaModal from 'shared/media-modal';
import removeIdPrefix from 'utils/removeIdPrefix';

import { formatPhoneNumber } from 'utils';
import { useGraphql } from 'context';
import { useImageValidator, useMediaSrc, useToggler } from 'hooks';
import FallbackThumbnail from '../media-fallback/FallbackThumbnail';

export default function ImageCard(props) {
  const {
    searchTerm,
    owner_info,
    recipient_info,
    recipient_id,
    owner_id,
    owner_eid,
    statusData,
    mediaLocation,
    clientKey,
    isVideo = false,
  } = props;
  const [modal, toggleModal] = useToggler();
  const { getImages } = useGraphql();
  const [imageSrc] = useMediaSrc(getImages)({ itemKey: mediaLocation });
  const [thumbnailSrc] = useMediaSrc(getImages)({
    itemKey: mediaLocation,
    parseScheme: (key) => key.replace('.jpeg', '_t.jpeg'),
  });
  const [isValidSrc] = useImageValidator(imageSrc);
  const isBlocked = statusData.status === MESSAGE_STATUS.BLOCKED;
  const {
    sxPaper,
    sxMemberInfoBox,
    sxContentBox,
    sxCreatedAtBox,
    sxPlayIconBox,
  } = useImageCardStyles({ isBlocked });
  return (
    <Paper elevation={3} onClick={() => toggleModal()} sx={sxPaper}>
      <Box sx={sxMemberInfoBox}>
        <MemberInfo
          label=""
          memberInfo={owner_info}
          memberId={owner_id}
          memberEid={formatPhoneNumber(owner_eid)}
          searchTerm={searchTerm}
          isMessageBlocked={isBlocked}
        />
      </Box>
      <Box sx={sxContentBox}>
        <Box sx={sxCreatedAtBox}>
          <Typography fontSize={12}>
            {format(new Date(clientKey), 'MMM dd, yyyy, hh:mm a')}
          </Typography>
        </Box>
        {isVideo ? (
          <Box sx={sxPlayIconBox}>
            <PlayArrowRoundedIcon sx={{ width: 50, height: 50 }} />
          </Box>
        ) : null}
        {isValidSrc ? (
          <CardMedia
            component="img"
            image={thumbnailSrc}
            sx={{ height: 150 }}
          />
        ) : (
          !isVideo && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
              >
                <FallbackThumbnail text="Image non available" />
              </Box>
            </Box>
          )
        )}
      </Box>
      <Box sx={{ display: 'flex' }}>
        <MemberInfo
          label="Recipient: "
          memberInfo={recipient_info}
          memberId={recipient_id}
          memberEid={formatPhoneNumber(removeIdPrefix(recipient_id))}
          searchTerm={searchTerm}
          isMessageBlocked={isBlocked}
          reverse={true}
        />
      </Box>
      {open ? (
        <MediaModal
          open={modal}
          toggleOpen={toggleModal}
          src={imageSrc}
          thumbnailSrc={thumbnailSrc}
          {...props}
        />
      ) : null}
    </Paper>
  );
}

const MESSAGE_STATUS = {
  ACTIVE: 'active',
  BLOCKED: 'blocked',
};

function useImageCardStyles({ isBlocked }) {
  return {
    sxPaper: {
      borderRadius: 4,
      backgroundColor: isBlocked && 'customPalette.error',
    },
    sxMemberInfoBox: { display: 'flex' },
    sxContentBox: {
      backgroundImage:
        'linear-gradient(to right top, #44444a, #344551,#1b484c,#1d4836,#374419)',
      alignItems: 'center',
      width: 250,
      height: 150,
      position: 'relative',
    },
    sxCreatedAtBox: {
      color: 'white',
      position: 'absolute',
      top: 5,
      left: 5,
      backgroundColor: 'rgba(0,0,0,0.3)',
      width: '150px',
      height: '20px',
      borderRadius: 2,
      padding: 0.5,
      zIndex: 200,
    },
    sxPlayIconBox: {
      color: 'white',
      position: 'absolute',
      top: 100,
      left: 125,
      backgroundColor: 'rgba(0,0,0,0.3)',
      borderRadius: '50%',
      padding: 0.5,
      width: 60,
      height: 60,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
}
