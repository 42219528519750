import React, { useRef, useState } from 'react';
import cs from 'classnames';
import styles from './MessagesList.module.scss';

import Box from '@mui/material/Box';
import format from 'date-fns/format';

import AudioMessage from 'modules/users-chats/chat-details/print-messages/audio-message';
import ImageMessage from 'modules/users-chats/chat-details/print-messages/image-message/ImageMessage';
import TextMessage from 'modules/users-chats/chat-details/print-messages/text-message';
import OwnerInfo from './OwnerInfo';
import MessageActions from 'modules/users-chats/chat-details/print-messages/message-actions/MessageActions';

import getMessageReadStatus from 'utils/getMessageReadStatus';
import useMessageInfo from 'hooks/useMessageInfo';

import { useQuery } from 'hooks';
import { useChatContext } from 'modules/users-chats/context';
import {
  useChangeFloatDate,
  useMessageScroll,
  useMessageStyle,
  useMessagePosition,
} from 'modules/users-chats/hooks';
import { ModerationFlag } from 'modules/users-chats/chat-details/print-messages/moderation-flag/ModerationFlag';
import { OWNER_TYPE } from 'modules/users-chats/constants';

const Message = (props) => {
  const {
    nextMessage,
    prevMessage,
    listRef,
    currentMessage,
    createdAt,
    clientKey,
    recipient_id,
    id,
    owner_id,
    owner_type,
    mediaType,
    owner_info,
    messages,
    callStatus,
    isMessageBlock,
  } = props;
  const messageRef = useRef();
  const queryParams = useQuery();
  const { channelInfo, channelLastMessageData, messageKey } = useChatContext();
  const { isMessageMine, showOwnerInfo, showDate } = useMessageInfo({
    messageData: currentMessage,
    nextMessageData: nextMessage,
    prevMessageData: prevMessage,
    currentKeyEqualZero: messageKey === '0',
  });

  const [showActions, setShowActions] = useState(false);
  const { nextDay, prevDay, nextMine, prevMine } = useMessagePosition({
    ...props,
  });

  const messageStyle = useMessageStyle({
    mine: isMessageMine,
    nextDay,
    prevDay,
    nextMine,
    prevMine,
    noNextMessage: !nextMessage,
  });

  const messageType = getMessageType(mediaType, callStatus);
  const MessageComponent = PromptComponents[messageType];
  const msgWithActions = mediaType === 'text' || mediaType === 'voice';

  const recipientStatus =
    channelInfo?.ChannelData?.memberStatuses?.memberOne?.id === recipient_id
      ? channelInfo?.ChannelData?.memberStatuses?.memberOne
      : channelInfo?.ChannelData?.memberStatuses?.memberTwo;

  useChangeFloatDate({ listRef, createdAt: clientKey, messageRef });
  useMessageScroll({ id, messageRef, clientKey });

  if (owner_type === OWNER_TYPE.BOTH) {
    return <ModerationFlag ref={messageRef} {...props} />;
  }

  return (
    <>
      {showDate ? (
        <div className={styles.date}>
          <p>{format(new Date(clientKey), 'd MMM')}</p>
          <div />
        </div>
      ) : null}
      <div
        ref={messageRef}
        className={cs(styles.message, isMessageMine && styles['message--mine'])}
        style={{ marginBottom: messageStyle.marginBottom }}
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: isMessageMine ? 'row-reverse' : 'row',
          }}
        >
          {showActions && msgWithActions ? (
            <MessageActions
              messageId={id}
              messages={messages}
              messageType={mediaType}
              isMessageBlock={isMessageBlock}
            />
          ) : null}
          <MessageComponent
            isMessageMine={isMessageMine}
            openModal={
              queryParams.messageId === props.id &&
              queryParams.origin === 'bookmark'
            }
            borderRadius={messageStyle.borderRadius}
            createdAt={createdAt}
            messageReadStatus={getMessageReadStatus({
              creationDate: clientKey,
              recipientLastInApp: recipientStatus?.lastInApp,
              recipientLastSeen: recipientStatus?.lastSeen,
              channelLastMessageDate: channelLastMessageData?.clientKey,
            })}
            {...props}
          />
        </Box>
        {showOwnerInfo ? (
          <OwnerInfo
            owner_id={owner_id}
            owner_info={owner_info}
            isMessageMine={isMessageMine}
          />
        ) : null}
      </div>
    </>
  );
};

export default Message;

function getMessageType(mediaType, callStatus = null) {
  if (mediaType === 'video' && callStatus === null) return 'video';
  if (callStatus !== null) return 'videoCall';
  return mediaType;
}

const PromptComponents = {
  image: ImageMessage,
  text: TextMessage,
  voice: AudioMessage,
};
