// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const aws_mobile = {
  aws_appsync_graphqlEndpoint:
    'https://3ltym7vd4bgnplihyjgbsrg3u4.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_user_files_s3_bucket:
    'kiwichatappstack-kiwichatmsgimgsaa0ddb06-iyqycegv3bl0',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_4PJFmH6KN',
  aws_user_pools_web_client_id: '4orlhntfs1rsobi2joesifrb89',
  aws_cognito_identity_pool_id:
    'us-east-1:59a82fc1-972b-4694-a6a6-1612981d4de6',
  API: {
    endpoints: [
      {
        name: 'accMgmtApiGateway',
        endpoint: 'https://uat-acc-mgmt-api.kiwichat.com'
      },
      {
        name: 'documentApiGateway',
        endpoint: 'https://uat-documents.kiwichat.com',
      },
    ],
  },
  Storage: {
    messageImgs: {
      bucket: 'kiwichatappstack-kiwichatmsgimgsaa0ddb06-1ss9n1i1ajkin',
      region: 'us-east-1',
    },
    videoRecordings: {
      bucket: 'kiwichatappstack-kiwichatvideorecordings69c634a1-1puyh8l7cqqh8',
      region: 'us-east-1',
    },
    docReportFiles: {
      bucket: 'kiwichatappstack-kiwichatdocreportfilescee2c3f8-c6oqtk1vqud5',
      region: 'us-east-1',
    }, // for doc report files
    grievanceAndForm: {
      bucket: 'kiwichatappstack-kiwichatgrievanceandformfilesed8-qpxt5fvgg8fb',
      region: 'us-east-1',
    },
    bulletins: {
      bucket: 'kiwichatappstack-kiwichatbulletinfilesfea9a418-qr3kgx85kgkf',
      region: 'us-east-1',
    },
    videoApp: {
      bucket: 'kiwichatappstack-kiwichatvideorecordings69c634a1-1puyh8l7cqqh8',
      region: 'us-east-1',
    },
    documents: {
      bucket: 'kiwichatappstack-kiwichatdocumentsde3420b0-1uy4a33rx2za2',
      region: 'us-east-1',
    },
    userPhotos: {
      bucket: 'kiwichatappstack-kiwichatuserphoto2406ec8a-rdlpenmgcpfp',
      region: 'us-east-1',
    }
  },
  logToSentry: true,
  environment: 'uat', // dev | test | uat | production
  Sentry: {
    api_base_url: 'https://o1416691.ingest.sentry.io',
    project_id: '6760568',
    sentry_key: 'd9d3e94f72504f04a49f7ae9353c67c2',
  },

  AppInfo: {
    platform: 'WEB',
    platformVersion: '0.0.0',
    version: '0.0.0',
  },
  kiwi: {
    logLevel: 'DEBUG',
  },
  enviroment: {
    env: 'UAT',
  },
};

export default aws_mobile;
