import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const Logo = (props) => (
  <SvgIcon width="273" height="40" viewBox="0 0 273 40" fill="none" {...props}>
    <path
      d="M124.149 30.1177L117.667 11.8589L117.522 11.4824H111.354L104.679 29.9765L102.212 21.7412L98.9467 11.4824H91.0135L100.543 38.9648L100.688 39.3412H108.258L111.016 31.3177L114.474 22.1177L117.909 31.3412L120.569 38.9648L120.69 39.3412H128.212L137.548 12.2118L137.814 11.4824H129.881L124.149 30.1177Z"
      fill={props.color || '#101010'}
    />
    <path
      d="M74.8328 12.4232L75.7035 11.482H66.4401L57.3702 21.7644V0.329102H49.9208V39.3644H57.3702V28.4467L67.4559 39.1762L67.6253 39.3644H77.4208L63.5619 24.8703L74.8328 12.4232Z"
      fill={props.color || '#101010'}
    />
    <path
      d="M84.1688 -4.73408e-05C83.2981 0.0470115 82.4757 0.352894 81.7743 0.847011C81.0729 1.34113 80.5408 2.02348 80.2506 2.82348C79.9603 3.62348 79.8878 4.47054 80.1055 5.29407C80.2989 6.1176 80.7343 6.84701 81.3631 7.43525C81.992 8.02348 82.766 8.39995 83.6125 8.56466C84.459 8.70584 85.3297 8.63525 86.1279 8.30583C86.926 7.97642 87.6032 7.43525 88.087 6.72936C88.5707 6.02348 88.8367 5.22348 88.8367 4.37642C88.8367 3.78819 88.74 3.22348 88.4981 2.68231C88.2804 2.14113 87.9418 1.64701 87.5065 1.24701C86.6116 0.399953 85.4023 -0.0235768 84.1688 -4.73408e-05Z"
      fill={props.color || '#101010'}
    />
    <path
      d="M148.432 11.4824H140.983V39.3412H148.432V11.4824Z"
      fill={props.color || '#101010'}
    />
    <path
      d="M87.8934 11.4824H80.444V39.3412H87.8934V11.4824Z"
      fill={props.color || '#101010'}
    />
    <path
      d="M269.848 32.7057L269.63 32.1175L269.05 32.3999C268.034 32.8704 266.922 33.1057 265.809 33.1528C265.374 33.1763 264.938 33.1057 264.527 32.941C264.116 32.7763 263.753 32.541 263.463 32.2351C262.858 31.4587 262.568 30.4704 262.641 29.5057V17.6704H270.622V11.4822H262.641V3.78809L255.772 4.49397H255.264V11.341H250.039V17.6234H255.264V29.4587C255.07 32.2116 255.989 34.941 257.827 37.0351C258.747 37.9057 259.859 38.5881 261.068 39.0351C262.278 39.4822 263.56 39.6704 264.866 39.5999C267.115 39.6234 269.316 39.1528 271.348 38.2351L271.831 38.0234L269.848 32.7057Z"
      fill={props.color || '#101010'}
    />
    <path
      d="M238.817 14.1884C235.963 11.9296 232.383 10.7767 228.707 10.9178C225.031 11.059 221.548 12.4943 218.887 14.9649C216.299 17.859 214.872 21.5767 214.872 25.412C214.872 29.2472 216.299 32.9649 218.887 35.859C221.524 38.3296 225.007 39.7884 228.683 39.9296C232.359 40.0708 235.939 38.8943 238.793 36.6355V39.3414H245.565V11.4825H238.793L238.817 14.1884ZM238.164 25.412C238.212 26.4472 238.019 27.5061 237.656 28.4708C237.269 29.459 236.713 30.3531 235.963 31.1061C234.415 32.4943 232.383 33.2708 230.279 33.2708C228.175 33.2708 226.143 32.4943 224.595 31.1061C223.193 29.5296 222.394 27.5061 222.394 25.3884C222.394 23.2943 223.168 21.2708 224.595 19.6708C226.143 18.259 228.175 17.4825 230.303 17.4825C232.432 17.4825 234.463 18.259 236.011 19.6708C237.463 21.2472 238.237 23.2943 238.164 25.412Z"
      fill={props.color || '#101010'}
    />
    <path
      d="M198.256 10.9409C195.185 10.8467 192.185 11.882 189.863 13.8115V0.329102H182.414V39.3644H189.863V24.7056C189.815 22.7997 190.541 20.9644 191.871 19.5762C192.548 18.9173 193.371 18.3762 194.265 18.0232C195.16 17.6703 196.128 17.5056 197.095 17.5291C197.942 17.482 198.764 17.6232 199.538 17.9291C200.312 18.235 201.013 18.7056 201.594 19.2938C202.755 20.7291 203.335 22.5409 203.239 24.3762V39.3409H210.688V23.9997C210.857 20.5409 209.648 17.1526 207.326 14.4938C206.165 13.2938 204.738 12.3762 203.19 11.7409C201.618 11.1526 199.949 10.8703 198.256 10.9409Z"
      fill={props.color || '#101010'}
    />
    <path
      d="M173.634 30.706C172.812 31.5531 171.82 32.2119 170.708 32.659C169.595 33.106 168.41 33.3178 167.225 33.2707C166.161 33.2943 165.072 33.106 164.081 32.706C163.089 32.306 162.17 31.7178 161.42 30.9884C159.993 29.4119 159.195 27.3884 159.195 25.2707C159.195 23.1766 159.993 21.1296 161.42 19.5531C162.17 18.8001 163.065 18.2354 164.057 17.8354C165.048 17.4354 166.112 17.2472 167.177 17.2707C169.474 17.2472 171.675 18.1178 173.32 19.6707L173.731 20.0707L178.423 15.2707L178.012 14.8943C176.585 13.506 174.868 12.4237 173.006 11.6943C171.143 10.9649 169.136 10.5884 167.104 10.6119C163.017 10.4707 159.026 11.9296 156.003 14.6354C153.269 17.506 151.77 21.2707 151.77 25.1766C151.77 29.0825 153.294 32.8472 156.003 35.7178C159.05 38.4237 163.065 39.859 167.177 39.6943C169.257 39.7413 171.313 39.3649 173.247 38.6119C175.182 37.859 176.924 36.7296 178.375 35.2943L178.786 34.9178L174.094 29.9766L173.634 30.706Z"
      fill={props.color || '#101010'}
    />
    <path
      d="M144.683 -4.73408e-05C143.812 0.0470115 142.99 0.352894 142.289 0.847011C141.587 1.34113 141.055 2.02348 140.765 2.82348C140.475 3.62348 140.402 4.47054 140.62 5.29407C140.838 6.1176 141.249 6.84701 141.878 7.43525C142.506 8.02348 143.28 8.39995 144.127 8.56466C144.973 8.70584 145.844 8.63525 146.642 8.30583C147.44 7.97642 148.118 7.43525 148.601 6.72936C149.085 6.02348 149.351 5.22348 149.351 4.37642C149.351 3.78819 149.254 3.22348 149.037 2.68231C148.819 2.14113 148.48 1.64701 148.045 1.24701C147.15 0.399953 145.941 -0.0235768 144.683 -4.73408e-05Z"
      fill={props.color || '#101010'}
    />
    <path
      d="M31.8777 9.34105C28.54 6.09399 24.0413 4.28223 19.3491 4.28223H1.64465V21.5058C1.64465 26.0705 3.5312 30.4469 6.84474 33.6705C10.1583 36.9175 14.657 38.7293 19.3491 38.7528H37.0536V21.5058C37.0536 16.9411 35.1912 12.5646 31.8777 9.34105ZM15.9147 29.5764C14.8263 29.1293 13.8346 28.494 13.0123 27.694C12.1899 26.894 11.5127 25.9293 11.0774 24.8705C10.6178 23.8116 10.4001 22.6822 10.4001 21.5293C10.376 16.7058 14.3909 12.7293 19.3491 12.7293C20.5343 12.7293 21.6952 12.9646 22.7836 13.3881C23.872 13.8352 24.8636 14.4705 25.686 15.2705C26.5083 16.0705 27.1855 17.0352 27.6209 18.094C28.0804 19.1528 28.2981 20.2822 28.2981 21.4352V30.1175V30.1646V30.2352H19.3491C18.164 30.2352 17.003 29.9999 15.9147 29.5764Z"
      fill={props.color || '#101010'}
    />
  </SvgIcon>
);

export default Logo;
