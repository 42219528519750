import React from 'react';

import RolesList from '../roles-list';
import { Skeleton } from 'shared/skeleton';

import { useAdminSettingsContext } from '../context';

const RolesSectionContent = () => {
  const { rolesArray, loading } = useAdminSettingsContext();
  return loading ? (
    <Skeleton amount={3} />
  ) : (
    <RolesList rolesArray={rolesArray} loading={loading} />
  );
};

export default RolesSectionContent;
