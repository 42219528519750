import { Card } from '@mui/material';

import CardInfo from './CardInfo';
import TextContent from './TextContent';
import CardActions from './CardActions';

export default function TextMessageCard({
  id = '',
  clientKey,
  text = '',
  owner = '',
  recipient = '',
  searchTerm = '',
  ownerId,
  date,
  channelId,
  statusData,
}) {
  const isBlocked = statusData?.status === 'blocked';
  const { sxCardWrapper } = useTextMessageCardStyles(isBlocked);

  return (
    <Card sx={sxCardWrapper} elevation={3}>
      <CardInfo
        id={id}
        owner={owner}
        recipient={recipient}
        searchTerm={searchTerm}
        date={date}
        ownerId={ownerId}
      />
      <TextContent text={text} searchTerm={searchTerm} />
      <CardActions
        id={id}
        clientKey={clientKey}
        ownerId={ownerId}
        channelId={channelId}
        isBlocked={isBlocked}
        reason={statusData.reason}
      />
    </Card>
  );
}

function useTextMessageCardStyles(isBlocked) {
  return {
    sxCardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      borderRadius: 4,
      paddingY: 1,
      border: isBlocked && '1 px solid',
      borderColor: isBlocked && 'customPalette.error',
    },
  };
}
