import Login from './modules/auth/login';
import PasswordForgot from './modules/auth/password-forgot';
import PasswordReset from './modules/auth/password-reset';
import NewPassword from './modules/auth/new-password';
import NotificationContainer from './shared/notification-container';

import routes from './routing/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAuthContext } from './modules/auth/context';

export default function UnprotectedApp() {
  const { challenge } = useAuthContext();
  return (
    <>
      <Switch>
        <Route
          path={routes.login}
          component={challenge ? PasswordReset : Login}
          exact
        />
        <Route path={routes.passwordForgot} component={PasswordForgot} exact />
        <Route path={routes.passwordReset} component={PasswordReset} exact />
        <Route path={routes.sigUp} component={PasswordReset} />
        <Route path={routes.newPassword} component={NewPassword} exact />
        <Redirect to={routes.login} />
      </Switch>
      <NotificationContainer />
    </>
  );
}
