import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useGraphql } from 'context';
import { useQueryCall } from 'hooks';
import { routes } from 'routing';
import { from } from 'rxjs';
import { useAuthContext } from '../context';

export default function usePasswordReset() {
  const [userSection, setUserSection] = useState();
  const [loadingUserSection, setLoadingUserSection] = useState(false);
  const [error, setError] = useState();
  const api = useGraphql();
  const { search } = useLocation();
  const requestId = search ? search.split('requestId=')[1] : '';

  const { data, loading: loadingResetPassword } = useQueryCall({
    sdkCmd: api.controller.doc.resetPassword,
    payload: requestId,
    enabled: !!requestId,
  });

  useEffect(() => {
    if (!data) return;
    setLoadingUserSection(true);
    from(
      api.auth.signIn({
        username: data?.username,
        password: data?.tempPassword,
      })
    ).subscribe({
      next: (res) => {
        setLoadingUserSection(false);
        if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setUserSection(res);
        } else {
          history.replace(routes.login);
        }
        setLoadingUserSection(false);
      },
      error: (err) => {
        setError(err.message);
        setLoadingUserSection(false);
      },
    });
  }, [data]);

  return {
    userSection,
    loading: loadingResetPassword || loadingUserSection,
    error,
  };
}
