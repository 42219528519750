// import { Fragment } from 'react';
//
// import styled from '@mui/material/styles/styled';
// import add from 'date-fns/add';
//
// import Form from 'shared/form';
// import Modal, { ModalTitle } from 'shared/modal';
// import BlockChatModalActions from 'shared/block-chat-modal/BlockChatModalActions';
//
// import { toast } from 'react-toastify';
//
// import {
//   DaysInput,
//   MultilineInput,
//   SelectInput,
//   SwitchInput,
// } from 'shared/inputs';
// import { useAuthContext } from 'modules/auth/context';
// import { If } from 'shared/utilities';
// import { useBlockChat, useToggler } from 'hooks';
//
// import { queryClient } from 'utils/queryClient';
// import { BLOCKING_REASONS } from 'modules/users-chats/constants';
// import { QUERY_KEYS } from 'utils';
//
// export default function BlockChatModal(props) {
//   const { channelInfoData, ElementModalControl } = props;
//
//   const { user } = useAuthContext();
//   const [openBlockChatModal, toggleOpenBlockChatModal] = useToggler(false);
//   const blockChat = useBlockChat();
//   const { id, initiator } = channelInfoData || {};
//
//   return (
//     <Fragment>
//       <ElementModalControl onClick={toggleOpenBlockChatModal} />
//       <If condition={openBlockChatModal}>
//         <BlockChatModalContainer open={openBlockChatModal}>
//           <ModalTitle text={'Block chat'} />
//           <StyledForm
//             initialValues={INITIAL_FORM_VALUES}
//             onSubmit={onSubmitHandler(
//               blockChat,
//               { id, user, ownerId: initiator },
//               toggleOpenBlockChatModal
//             )}
//           >
//             <DaysInput
//               name="days"
//               postLabel="days"
//               indefinitelyName="indefinitely"
//               label="Block chat for"
//               min={0}
//               max={999}
//             />
//             <StyledSwitchInput
//               name="indefinitely"
//               label="Block indefinitely"
//               color="primary"
//             />
//             <SelectInput
//               name="reason"
//               label="Blocking reason"
//               placeholder="Choose a reason"
//               options={BLOCKING_REASONS}
//               variant="outlined"
//               outlined
//               required
//             />
//             <StyledMultilineInput
//               name="note"
//               label="Internal notes"
//               placeholder="Type here"
//               helperText="Optional (not visible for the user)"
//               maxLength={500}
//             />
//             <BlockChatModalActions
//               onClick={toggleOpenBlockChatModal}
//               mutation={blockChat}
//             />
//           </StyledForm>
//         </BlockChatModalContainer>
//       </If>
//     </Fragment>
//   );
// }
//
// const onSubmitHandler =
//   (mutation, { id, user, ownerId }, closeModal) =>
//   (form) => {
//     console.log('form', form);
//     const begins = new Date().toISOString();
//     const ends = add(
//       new Date(),
//       form.indefinitely ? { years: 10 } : { days: form.days }
//     ).toISOString();
//
//     mutation.mutate(
//       {
//         id,
//         reason: form.reason,
//         comment: form.note,
//         begins,
//         ends,
//         blockedBy: {
//           id: user.id,
//           firstName: user.firstName,
//           lastName: user.lastName,
//         },
//         indefinite: form.indefinitely,
//       },
//       {
//         onSuccess: () => {
//           closeModal();
//           toast.success('Chat blocked successfully');
//           queryClient.setQueryData(
//             [QUERY_KEYS.USER_CHATS, ownerId],
//             (oldData) => {
//               if (!oldData) return;
//               const newData = oldData.map((chat) => {
//                 if (chat.channelId === id) {
//                   const newChatData = {
//                     ...chat,
//                     isChannelBlock: true,
//                     channelBlockReason: form.reason,
//                     blockedBy: `${user.id};${user.firstName};${user.lastName}`,
//                     begins: begins,
//                     blockedUntil: ends,
//                   };
//                   return newChatData;
//                 }
//                 return chat;
//               });
//               return newData;
//             }
//           );
//           queryClient.setQueryData([QUERY_KEYS.CHANNEL_INFO, id], (oldData) => {
//             const newData = {
//               ...oldData,
//               ChannelData: {
//                 ...oldData.ChannelData,
//                 isChannelBlock: true,
//               },
//             };
//             return newData;
//           });
//         },
//       }
//     );
//   };
//
// const INITIAL_FORM_VALUES = {
//   days: 1,
//   indefinitely: false,
//   note: '',
//   reason: 'Other',
// };
//
// const BlockChatModalContainer = styled(Modal)({
//   width: 584,
// });
//
// const StyledForm = styled(Form)(({ theme }) => ({
//   padding: '16px 0 0 0',
// }));
//
// const StyledSwitchInput = styled(SwitchInput)({
//   marginTop: 16,
// });
//
// const StyledMultilineInput = styled(MultilineInput)({
//   marginTop: 40,
// });

import styles from './BlockChat.module.scss';

import add from 'date-fns/add';
import Grid from '@mui/material/Grid';

import Form from 'shared/form';
import Modal, { ModalTitle } from 'shared/modal';
import Button from 'shared/button';
import FormLoader from 'shared/form-loader';

import { toast } from 'react-toastify';
import {
  DaysInput,
  MultilineInput,
  SelectInput,
  SwitchInput,
} from 'shared/inputs';
import { useFormContext } from 'react-hook-form';
import { useAuthContext } from 'modules/auth/context';
import { useChatContext } from 'modules/users-chats/context';
import { useBlockChat, useUserProfileContext } from 'modules/users-chats/hooks';

import { QUERY_KEYS } from 'utils';
import { queryClient } from 'utils/queryClient';
import { BLOCKING_REASONS, USER_CATEGORY } from 'modules/users-chats/constants';

export default function BlockChatModal() {
  const { user } = useAuthContext();
  const { channelInfo, blockChatModal, toggleBlockChatModal } =
    useChatContext();
  const { userId } = useUserProfileContext();
  const { id, members } = channelInfo?.ChannelData || {};
  const category = members[0]?.__typename;
  const blockChat = useBlockChat();

  function blockChatHandleSubmit(form) {
    const begins = new Date().toISOString();
    const ends = add(
      new Date(),
      form.indefinitely ? { years: 10 } : { days: form.days }
    ).toISOString();

    blockChat.mutate(
      {
        id,
        reason: form.reason,
        comment: form.note,
        begins,
        ends,
        blockedBy: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
        },
        indefinite: form.indefinitely,
      },
      {
        onSuccess: () => {
          toggleBlockChatModal();
          toast.success('Chat blocked successfully');
          queryClient.setQueryData(
            [QUERY_KEYS.USER_CHATS, userId],
            (oldData) => {
              const newData = oldData.map((chat) => {
                if (chat.channelId === id) {
                  return {
                    ...chat,
                    isChannelBlock: true,
                    channelBlockReason: form.reason,
                    blockedBy: `${user.id};${user.firstName};${user.lastName}`,
                    begins: begins,
                    blockedUntil: ends,
                  };
                }
                return chat;
              });
              return newData;
            }
          );
          queryClient.setQueryData([QUERY_KEYS.CHANNEL_INFO, id], (oldData) => {
            return {
              ...oldData,
              ChannelData: {
                ...oldData.ChannelData,
                isChannelBlock: true,
              },
            };
          });
        },
      }
    );
  }

  return (
    <Modal className={styles.modal} open={blockChatModal}>
      <ModalTitle text={'Block chat'} />
      <Form
        className={styles.form}
        initialValues={INITIAL_VALUES}
        onSubmit={blockChatHandleSubmit}
      >
        <DaysInput
          name="days"
          postLabel="days"
          indefinitelyName="indefinitely"
          label="Block chat for"
          min={0}
          max={999}
        />
        <SwitchInput
          className={styles.form__switcher}
          name="indefinitely"
          label="Block indefinitely"
          color="primary"
        />
        <div>
          <SelectInput
            name="reason"
            label="Blocking reason"
            placeholder="Choose a reason"
            options={BLOCKING_REASONS}
            variant="outlined"
            outlined
            required
          />
        </div>
        <MultilineInput
          className={styles.form__note}
          name="note"
          label="Internal notes"
          placeholder="Type here"
          helperText={`Optional (not visible ${
            category === USER_CATEGORY.INMATE ? 'to inmate' : 'for the user'
          })`}
          maxLength={500}
        />
        <Grid
          classes={{ root: styles.form__submit }}
          direction="row-reverse"
          justifyContent="space-between"
          rap="nowrap"
          container
        >
          <Grid justifyContent="flex-end" wrap="nowrap" container item>
            <Button
              className={styles.button}
              onClick={() => toggleBlockChatModal(false)}
              display="secondary"
            >
              Cancel
            </Button>
            <SubmitButton loading={blockChat.isLoading} />
          </Grid>
          <FormLoader loading={blockChat.isLoading} />
        </Grid>
      </Form>
    </Modal>
  );
}

const SubmitButton = ({ loading }) => {
  const { formState } = useFormContext();

  return (
    <Button
      className={styles.button}
      type="submit"
      display="primary"
      disabled={!formState?.dirtyFields?.reason || loading}
    >
      Block
    </Button>
  );
};

const INITIAL_VALUES = {
  days: 1,
  indefinitely: false,
  note: '',
  reason: 'Other',
};

const getFullName = (user) => `${user?.firstName} ${user?.lastName}`;
