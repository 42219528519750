import { useGraphql } from 'context';

const getColor = (enviroment) => {
  switch (enviroment) {
    case 'DEV':
      return '#038768'; //brand-green 2
    default:
      return '#101010'; //brand-grey 1
  }
};

const useColor = () => {
  const { env: enviroment } = useGraphql();

  return getColor(enviroment);
};

export default useColor;
