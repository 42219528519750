import React, { useEffect, useCallback, useMemo, useState } from 'react';
import cs from 'classnames';
import styles from './SignUp.module.scss';

import Form from 'shared/form';
import { TextInput } from 'shared/inputs';
import Button from 'shared/button';
import { Skeleton } from 'shared/skeleton';
import PasswordInput from '../password-input';

import { Dot, Checkmark } from './Icons';

import * as yup from 'yup';
import { useFormContext } from 'react-hook-form';
import { useAuthContext } from '../context';
import { If } from 'shared/utilities';

const validation = yup.object({
  email: yup.string().email().lowercase().trim(),
  newPassword: yup
    .string()
    .test(
      'newPassword',
      'Password must be at least 8 characters, include UPPER/lowercase and numbers',
      (value) => {
        if (value.length < 8) return false;
        if (!/[~!@#$%^*\-_=+[{}\]/;:,.?]+/.test(value)) return false;
        if (!/[A-Z]+/.test(value)) return false;
        if (!/[a-z]+/.test(value)) return false;
        if (!/[0-9]+/.test(value)) return false;
        return true;
      }
    )
    .required(),
  confirmPassword: yup
    .string()
    .test('confirmPassword', "Password doesn't match", function (value) {
      return value === this.parent.newPassword;
    }),
});

const PASSWORD_VALIDATION = {
  LENGTH: 'At least 8 characters',
  LOWER_CASE: 'At least 1 lower case letter',
  UPPER_CASE: 'At least 1 upper case letter',
  NUMBER: 'At least 1 number',
  SPECIAL_SYMBOL:
    'At least 1 special character (~ ! @ # $ % ^ * - _ = + [ { ] } / ; :,. ?)',
};

const NewPasswordInput = ({ name }) => {
  const { watch, getValues, trigger } = useFormContext();

  const value = watch(name);

  const valid = useMemo(() => {
    const valid = {
      LENGTH: false,
      LOWER_CASE: false,
      UPPER_CASE: false,
      NUMBER: false,
      SPECIAL_SYMBOL: false,
    };

    valid.LENGTH = value.length > 7;
    valid.LOWER_CASE = /[a-z]+/.test(value);
    valid.UPPER_CASE = /[A-Z]+/.test(value);
    valid.NUMBER = /[0-9]+/.test(value);
    valid.SPECIAL_SYMBOL = /[~!@#$%^*\-_=+[{}\]/;:,.?]+/.test(value);

    return valid;
  }, [value]);

  useEffect(() => {
    if (getValues().confirmPassword.length) {
      trigger('confirmPassword');
    }
  }, [value]);

  return (
    <>
      <PasswordInput
        className={styles.input}
        name={name}
        label="New password"
        inputProps={{ autoComplete: 'new-password' }}
        nativeOutline
      />
      <div className={styles.passwordValidation}>
        {Object.entries(valid).map(([key, value]) => (
          <div
            key={key}
            className={cs(
              styles.passwordValidation__item,
              value && styles['passwordValidation__item--valid']
            )}
          >
            {value ? <Checkmark /> : <Dot />}
            <p>{PASSWORD_VALIDATION[key]}</p>
          </div>
        ))}
      </div>
    </>
  );
};

const ConfirmPasswordInput = ({ name }) => {
  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  return (
    <PasswordInput
      name={name}
      label="Confirm password"
      error={!!error}
      helperText={error?.message || ''}
      inputProps={{ autoComplete: 'confirm-new-password' }}
      nativeOutline
    />
  );
};

const SubmitButton = () => {
  const { formState } = useFormContext();

  return (
    <Button
      className={styles.button}
      display="primary"
      type="submit"
      disabled={!formState.isValid}
    >
      Login
    </Button>
  );
};

const NewPasswordForm = ({ user }) => {
  const { setNewPassword, toggleLoading, loading } = useAuthContext();
  const handleSubmit = useCallback(
    (form) => {
      setNewPassword({ form, user });
      toggleLoading(false);
    },
    [setNewPassword]
  );

  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  };
  return (
    <Form
      className={styles.form}
      initialValues={initialValues}
      validation={validation}
      onSubmit={handleSubmit}
    >
      <If condition={!loading} otherwise={<Skeleton amount={2} />}>
        <NewPasswordInput name="newPassword" />
        <ConfirmPasswordInput name="confirmPassword" />
        <SubmitButton />
      </If>
    </Form>
  );
};

export default NewPasswordForm;
