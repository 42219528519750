import CardMedia from '@mui/material/CardMedia';
import styled from '@mui/material/styles/styled';

import ImageMessageContainer from './ImageMessageContainer';

import { useGraphql } from 'context';
import { toTitleCase } from 'utils';
import { useImageValidator, useMediaSrc } from 'hooks';

export default function ImageMessage(props) {
  const {
    mediaLocation,
    clientKey,
    owner_info,
    recipient_info,
    isMessageBlock,
  } = props;

  const { getImages } = useGraphql();
  const [thumbnailSrc] = useMediaSrc(getImages)({
    itemKey: mediaLocation,
    parseScheme: (key) => key.replace('.jpeg', '_t.jpeg'),
  });
  const [isValidSrc] = useImageValidator(thumbnailSrc);

  const thumbnailAlt = `Image from ${toTitleCase(owner_info)} to ${toTitleCase(
    recipient_info
  )}`;

  return (
    <ImageMessageContainer
      isValidSrc={isValidSrc}
      clientKey={clientKey}
      isMessageBlock={isMessageBlock}
      altTitle={thumbnailAlt}
    >
      <StyledCardMedia
        image={thumbnailSrc}
        title={thumbnailAlt}
        component="img"
      />
    </ImageMessageContainer>
  );
}

const StyledCardMedia = styled(CardMedia)(({ clickable = false }) => ({
  width: 280,
  height: 280,
  cursor: clickable ? 'pointer' : 'default',
}));
